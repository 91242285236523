import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from 'src/app/app.constants';

@Injectable()
export class DataService {

    constructor(private http: HttpClient, private configuration: Configuration) {

    }

    //public accept<T>(patientdata: string, publicToken: string, tokenPartner: string, req: any, accept: string): Observable<T> {
    public accept<T>(patientdata: string, tokenPartner: string, req: any, accept: string): Observable<T> {
        //const json = { patientdata: patientdata, token: token};

        /*
        const json = {
            patientData: patientdata,
            tokenPartner: tokenPartner, 
            publicToken: publicToken,
            "metadata": {
                "ip": req.ip,
                "userAgent": req.userAgent,
                "timestamp": Date.now().toString(),
                "purpose": "Autorização de coleta e compartilhamento com terceiros de Dados Clínicos"
            }
        };

        let endpoint = "";
        if (accept == "yes"){
            //endpoint = "accept";
            endpoint = "new";
        } else if (accept == "no"){
            endpoint = "cancel";
        }
        
        //const actionUrl = this.configuration.ServerWithApiUrl + 'agreement/' + endpoint;
        const actionUrl = this.configuration.ServerWithApiUrl + 'agreement';
        //console.log(actionUrl);
        return this.http.post<T>(actionUrl, json);
        */
        const json = { partner: tokenPartner, data: patientdata };
        const actionUrl = this.configuration.TuneServer + '/api/agreement/terms';
        console.log(actionUrl);
        console.log(json);

        if (accept === 'yes') {
            return this.http.post<T>(actionUrl + '/accept', json);
        } else if (accept === 'no') {
            return this.http.post<T>(actionUrl + '/reject', json);
        }

    }

    public acceptSpecial<T>(publicToken, accept): Observable<T> {

        const json = {
            'partner': 'NOVAMED-BRADESCO-HOMOL',
            'metadados': {
                'patient': {'publicToken': publicToken}
            }
        };

        const header = new HttpHeaders({ 'Authorization': 'Bearer 05f7f4e506103544792a8d5c8a462e5997992174'});

        const requestOptions = {  headers: header, body: json };

        if (accept === 'yes') {
            const actionUrl = this.configuration.TuneServer + '/api/agreement/consent';
            return this.http.post<T>(actionUrl, json, requestOptions);
        } else {
            const actionUrl = this.configuration.TuneServer + '/api/agreement/revoke';
            return this.http.post<T>(actionUrl, json, requestOptions);
        }
    }

    public home<T>(patientdata: string, publicToken: string, tokenPartner: string): Observable<T> {
        //const json = { patientdata: patientdata, token: token};

        const json = {
            application: 'AgrrementAPI',
            resource: 'Home',
            status: 'success',
            value: {
                patientData: patientdata,
                tokenPartner: tokenPartner,
                publicToken: publicToken
            }
        };

        //todo() - adicionar IP

        const actionUrl = this.configuration.ServerWithApiUrl + 'log';

        if (patientdata && publicToken && tokenPartner) {
            return this.http.post<T>(actionUrl, json);
        } else {
            return this.http.get<T>(actionUrl);
        }

    }

    public sendMail<T>(): Observable<T> {
        let actionUrl = this.configuration.MailServer;//'https://mail.dataopera.com.br/';
        //let actionUrl = 'http://localhost:3000/EmailDataMea';
        let headers = new HttpHeaders({
            'Access-Control-Allow-Origin': 'https://agreement.datamea.com.br'
        });
        let options = { headers: headers };

        //console.log(actionUrl);
        return this.http.post<T>(actionUrl, null, options);
    }
}

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        return next.handle(req);
    }
} 
