import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {
    //public Server = 'https://emr-prod.ezorder.com.br/';
    //public ApiUrl = 'v1/';
    //public Server = 'https://tune-stg.dataopera.com.br/';
    //public Server = 'http://localhost:3006';
    public Server = 'https://agreementapi.dataopera.com.br';
    public ApiUrl = '/';
    public ServerWithApiUrl = this.Server + this.ApiUrl;

    public MailServer = 'https://mail.dataopera.com.br/';
    public TuneServer = 'https://tune-stg.dataopera.com.br';
    //public TuneServer = 'http://54.94.45.151:3006';
    //public TuneServer = 'http://localhost:3000';
}
