import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { AgreementComponent } from './agreement/agreement.component';
import { AgreementCNUComponent } from './agreement/cnu/agreement-cnu.component';




const appRoutes: Routes = [
    // {
    //   path: '',
    //   component: AgreementComponent,
    // },
    {
      path: '',
      component: AgreementComponent,
      data: { title: 'DataOpera - Consentimento' }
    },
    {
      path: 'aba83ea0d0c842c09745f39f06263c51/:key',
      component: AgreementCNUComponent,
      data: { title: 'DataOpera - Consentimento' }
    },
    {
      path: ':key',
      component: AgreementComponent,
      data: { title: 'DataOpera - Consentimento' }
    },
    {
      path: ':partner/:key',
      component: AgreementComponent,
      data: { title: 'DataOpera - Consentimento' }
    },
    /*{
      path: 'p/:key/:token',
      component: AgreementComponent,
      data: { title: 'DataOpera - Consentimento' }
    }*/
    // ,
    // { path: '',
    //   redirectTo: 'p/:key',
    //   pathMatch: 'full'
    // }
  ];

  @NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
    exports: [RouterModule],
    // providers: [DataService]
  })
  export class AppRoutingModule { }
