import { Component, OnInit, Inject, Renderer2, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';

import { AgreementTermsComponent } from '../agreement-terms/agreement-terms.component';
import { AgreementTermsSulAmericaComponent } from '../agreement-terms-sulamerica/agreement-terms-sulamerica.component';
import { DataService } from '../shared/services/dataservice';

import { validateBr } from 'js-brasil';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})

export class AgreementComponent implements OnInit, AfterViewInit {

  public patientData = '';
  public patientToken = '';
  public partnerToken = '';
  public req: any = {};

  public carteirinhasEncoded = '';

  public carteirinhas = '';
  public beneficiarioArray: any[];
  public beneficiarioArrayToShow: any[];


  public isValid = false;

  public isCollapssed = true;
  public returnedValue: any[];

  public logo = '';
  public show = false;
  public homeSulAmerica = false;
  public homeCNU = false;
  public strCPF = '';
  public token = '';

  // public isValid: boolean = false;

  public configTermos: any[];

  // public validKeys: string[] = ["7b2dbdd54d3ab62a11c435a96d902dba","2d71af3f80745b6e6eff9fbab91a348c",
  // "a8044577a2d2f0ed02a57f181993b9a2",
  //               "a5b95592427fd9dc88a4b0f2d9410411", "cf8ba9baa1a663fa3ace22a347a4b4dd", "1c17c193f3f15c9b8a3f4f453b4ce0fa",
  //               "9b859eaed6601a01b273bf27ebef2773","ef984a2c915374d0eef460f76e17ce2d","a3ac6af539a3fe35c7761461b5e3f3a2",
  //               "fba757d73c1917d260cf61059fd3556e"];

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private render: Renderer2
  ) {
  }

  ngOnInit() {
    //debugger
  //  console.log('OnInit AgreementComponent');
    this.validateParams(this.route.snapshot.params);

    this.configTermos = [{
      'politicaPrivacidade': true,
      'termos': true,
      'autorizacaoUso': true,
      'politicaSaude': true,
    }];

    // this.loadHome();

    // console.log(this.route);

  }


  ngAfterViewInit() {
    const classArr: any = document.querySelectorAll('.collapsible');
    classArr.forEach(element => {
      this.render.listen(element, 'click', (target) => {

        element.classList.toggle('active');
        const content = element.nextElementSibling;
        if (content.style.display === 'inherit') {
          this.isCollapssed = true;
          content.style.display = '';
          // content.style.display = 'none';
          this.beneficiarioArrayToShow = [this.beneficiarioArray[0]];
        } else {
          this.isCollapssed = false;
          content.style.display = 'inherit';
          this.beneficiarioArrayToShow = this.beneficiarioArray;
        }
      });
    });
   // console.log(classArr);
  }

  validateParams = (params) => {
  //  console.log('validateParams');
    // console.log(params);

    // if(params.key && params.token){


   // console.log('clientCode = ' + JSON.stringify(params.client));

    const newKey = params.key;
    const keyComplete = newKey.split('|');

    this.patientData = decodeURIComponent(keyComplete[1]);
   // console.log('patientData:' + this.patientData);
    // this.patientToken = params.token;
    this.partnerToken = keyComplete[0];
    this.carteirinhasEncoded = keyComplete[1];

    if (keyComplete.length >= 3) {
      this.token = keyComplete[2];
    }
  //  console.log('patient token:' + this.token);


    this.req = this.generateReq();

    this.manipulateTemplate(this.partnerToken);

    if (this.homeCNU) {
      this.carteirinhas = atob(this.carteirinhasEncoded);
      this.beneficiarioArray = JSON.parse(this.carteirinhas);
      this.beneficiarioArrayToShow = this.beneficiarioArray.slice(0, 1);
  //    console.log('this.carteirinhas=' + this.carteirinhas);
    }

    /*let keyComplete = JSON.parse(params.key);

    this.patientData = keyComplete.criptoContent;
    this.patientToken = params.token;

    //console.log("patientKey = " + this.patientData);
    //console.log("patientToken = " + this.patientToken);

    this.manipulateTemplate(keyComplete.cliente);*/
  }



  generateReq = () => {
    return {
      'ip': '167.250.29.235',
      'userAgent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.142 Safari/537.36'
    };
  }

  manipulateTemplate = (cliente) => {
  //  console.log('manipulateTemplate');
    const logos = {
      'ac13988d5fe9c8b959c8cf54fa23a3b6': 'logo-apm.svg', // APM
      '22c01d5f9a3ef307e4c169501be3cf71': 'logo-qualicorp.png', // QualiCorp
      'f00eaccc09544aa4cba7cfccd92d696b': 'sulamerica.jpg', // SulAmerica
      '615d3f581055395d67a134e91d5c9ac0': 'sulamerica.jpg', // SulAmerica
      '0298e3cea3d2f28cd21cd41bdb054b6c': 'totvs.png', // Totvs
      '6c0d9548655d3047436ef4a00cf2218b': 'clinicweb.png', // Clinicweb
      '56da8bc8956bf0ff4d3f82ffa591a83f': 'prontmed.png',   // Prontmed
      'aba83ea0d0c842c09745f39f06263c51': 'unimed-cnu.png', // Unimed-CNU
      'adbd903f5a3449edb85ae96cc965f276': 'bp.png', // BeneficienciaPortuguesa - Homol
      '426b3532ab4443ebbd8d5ed38b5fd679': 'bradesco.png', // Bradesco - Homol
      '77194d92c21b42a9bf76d6b3190a13b9': 'cassi.png',
      'f4a9a1a79cd54ee3af56eb439aaadc4c': 'cejam.jpg', //POC- CEJAM
    };

    if (cliente != null && cliente !== '' && logos.hasOwnProperty(cliente)) {
      this.logo = logos[cliente];
      this.show = true;
    } else {
      this.show = false;
    }

    if (cliente === 'aba83ea0d0c842c09745f39f06263c51') {
      this.homeCNU = true;
    }

    // SOMENTE CNU tem caso de validação, antes de realizar post
    if (!this.homeCNU) {
      this.isValid = true;
    }

  }

  openDialog = (type: string) => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      termType: type
    };

    if (this.partnerToken === 'f00eaccc09544aa4cba7cfccd92d696b' || this.partnerToken === '615d3f581055395d67a134e91d5c9ac0') {
      this.dialog.open(AgreementTermsSulAmericaComponent, dialogConfig);
    } else {
      this.dialog.open(AgreementTermsComponent, dialogConfig);
    }


  }

  // loadHome() {
  //   this.dataService
  //     .home<any[]>(this.patientData, this.patientToken, this.partnerToken)
  //     //.sendMail()
  //     .subscribe(data => console.log(JSON.stringify(data)),
  //       error => () => {
  //         // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
  //         console.log("Ocorreu um erro ao enviar o consentimento!");
  //         //this.showFailMessage();
  //       },
  //       () => {
  //         //this.showSucessMessage();
  //         //console.log("this.returnedValue = " + this.returnedValue.toString());
  //       });
  // }


  accept = () => {

    this.showAcceptSucessMessage();

    if ((this.partnerToken === '426b3532ab4443ebbd8d5ed38b5fd679') //bradesco-homol
        || (this.partnerToken === '77194d92c21b42a9bf76d6b3190a13b9') //cassi-homol
        || (this.partnerToken === 'f4a9a1a79cd54ee3af56eb439aaadc4c') //cejam-homol
      ) {
      this.dataService
        .acceptSpecial(this.token, 'yes')
        .subscribe((data) => {
          // this.dataService.sendMail().subscribe((data) => {
          //   console.log('RETURN EMAIL: '+ data);
          // },
          // error => () => {
          //   // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
          //   console.log("Ocorreu um erro ao enviar o email!");
          //   //this.showFailMessage();
          // },
          // () => {
          //   //this.showSucessMessage();
          //   console.log("this.returnedValueEmail = " + this.returnedValue.toString());
          // });
        //  console.log(JSON.stringify(data));
        },
          error => () => {
            // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
            console.log('Ocorreu um erro ao enviar o consentimento!');
         
          },
          () => {
           // this.showAcceptSucessMessage();
            // console.log("this.returnedValue = " + this.returnedValue.toString());
          });
    }

    // this.dataService
    //   .accept<any[]>(this.patientData, this.partnerToken, this.req, 'yes')
    //   // .sendMail()
    //   .subscribe((data) => {
    //     // this.dataService.sendMail().subscribe((data) => {
    //     //   console.log('RETURN EMAIL: '+ data);
    //     // },
    //     // error => () => {
    //     //   // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
    //     //   console.log("Ocorreu um erro ao enviar o email!");
    //     //   //this.showFailMessage();
    //     // },
    //     // () => {
    //     //   //this.showSucessMessage();
    //     //   console.log("this.returnedValueEmail = " + this.returnedValue.toString());
    //     // });
    //     console.log(JSON.stringify(data));
    //   },
    //     error => () => {
    //       // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
    //       console.log('Ocorreu um erro ao enviar o consentimento!');
    //       this.showFailMessage();
    //     },
    //     () => {
    //       this.showAcceptSucessMessage();
    //      // console.log("this.returnedValue = " + this.returnedValue.toString());
    //     });
  }


  cancel = () => {
    this.showRejectSucessMessage();

    console.log('this.partnerToken == "' + this.partnerToken + '"');
    if ((this.partnerToken === '426b3532ab4443ebbd8d5ed38b5fd679') //bradesco-homol
        || (this.partnerToken === '77194d92c21b42a9bf76d6b3190a13b9') //cassi-homol
        || (this.partnerToken === 'f4a9a1a79cd54ee3af56eb439aaadc4c') //cejam-homol
      ) {
    this.dataService
        .acceptSpecial(this.token, 'no')
        .subscribe((data) => {
          // this.dataService.sendMail().subscribe((data) => {
          //   console.log('RETURN EMAIL: '+ data);
          // },
          // error => () => {
          //   // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
          //   console.log("Ocorreu um erro ao enviar o email!");
          //   //this.showFailMessage();
          // },
          // () => {
          //   //this.showSucessMessage();
          //   console.log("this.returnedValueEmail = " + this.returnedValue.toString());
          // });
        //  console.log(JSON.stringify(data));
        },
          error => () => {
            // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
            console.log('Ocorreu um erro ao enviar o consentimento!');
           // this.showFailMessage();
          },
          () => {
          //  this.showAcceptSucessMessage();
            // console.log("this.returnedValue = " + this.returnedValue.toString());
          });
        }
    // this.dataService
    //   .accept<any[]>(this.patientData, this.partnerToken, this.req, 'no')
    //   // .sendMail()
    //   .subscribe(data => console.log(JSON.stringify(data)),
    //     error => () => {
    //       // this.toasterService.pop('error', 'Damn', 'Something went wrong...');
    //       console.log('Ocorreu um erro ao enviar o consentimento!');
    //       this.showFailMessage();
    //     },
    //     () => {
    //       this.showRejectSucessMessage();
    //       // console.log("this.returnedValue = " + this.returnedValue.toString());

    //       // document.getElementById("main").style.backgroundColor = '#EFF8FB';
    //       // document.getElementById("main").style.maxHeight = "500px";
    //       // tslint:disable-next-line:max-line-length
    //       // document.getElementById("main").innerHTML = "<p style=\"text-align: center; vertical-align: middle;
    // justify-content: center; align-items: center; \">" +
    //       //   "Agradecemos a atenção.<br>" +
    //       //   "Esperamos que reconsidere a decisão no futuro" +
    //       //   "</p>";

    //     });

  }

  showAcceptSucessMessage = () => {
    const msg = '<h2>Mensagem de Aceite de Consentimento</h2>' +
      '<p>Agradecemos a sua autorização* para compartilhamento dos dados como representante legal.</p>' +
      '<p>Esta atitude é muito importante para a prevenção e o acompanhamento da ' +
      'saúde de cada titular e dependentes do plano de saúde.</p>' +
      '<br>' +
      '<p><small>* Para revogar a sua autorização, acesse o portal do cliente, ' +
      'termos de autorização gerais e salve a sua preferência como "Não".</small></p>';

    this.showMessage(msg);
  }

  showRejectSucessMessage = () => {
    const msg = '<h2>Mensagem de Discordância de Consentimento</h2>' +
      '<p>Agradecemos a sua atenção.<br />' +
      'Esperamos que reconsidere a decisão no futuro.</p>';

    this.showMessage(msg);
  }

  showFailMessage = () => {
    const msg = 'Ocoreu um problema ao enviar a sua resposta.<br>' +
      'Tente fechar esta pagina e realizar o processo de consentimento novamente.<br>';

    this.showMessage(msg);
  }

  showMessage = (msg: string) => {
    document.getElementById('main').style.backgroundColor = '#EFF8FB';
    document.getElementById('main').style.maxHeight = '500px';
    document.getElementById('main').style.textAlign = 'center';
    document.getElementById('main').innerHTML = '<p style="text-align: center; vertical-align: middle; justify-content: center;'
      + '"align-items: center; ">' + msg + '</p>';
  }


  ValidaCPF = () => {
    this.isValid = validateBr.cpf(this.strCPF);
  }
}
