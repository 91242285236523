import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export interface DialogData {
  termType: string;
}


@Component({
  selector: 'app-agreement-terms-sulamerica',
  templateUrl: 'agreement-terms-sulamerica.component.html',
  styleUrls: ['agreement-terms-sulamerica.component.scss']
})
export class AgreementTermsSulAmericaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AgreementTermsSulAmericaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {  }

  close() {
    if(document.getElementById(this.data.termType))
    {
      document.getElementById(this.data.termType).style.visibility = "hidden"; 
      document.getElementById(this.data.termType).style.height = "0"; 
    }

    this.dialogRef.close();
  }

  ngOnInit() {
    if(document.getElementById(this.data.termType))
    {
      document.getElementById(this.data.termType).style.visibility = "visible"; 
      document.getElementById(this.data.termType).style.height = "auto"; 
    }
  }

  print() {
    var element = document.getElementById(this.data.termType);
    element.style.margin ="0px auto 0px auto"; 
    element.style.overflow = "hidden";
    var printHtml = element.outerHTML;
    var currentPage = document.body.innerHTML;
    var elementPage = '<html><head><title></title></head><body>' + printHtml + '</body>';
    //change the body
    document.body.innerHTML = elementPage;
    //print
    window.print();
    //go back to the original
    document.body.innerHTML = currentPage;
}
  

}
